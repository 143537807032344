import Service from "../Service";

const resource = "commitmentcarriermaterial/";

export default {
    save(tccm, requestID) {
        return Service.post(resource + "save", tccm, {
            params: { requestID }
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID }
        });
    },

    downloadtemplate(obj, requestID) {
        console.log('desde el servicio', obj)
        return Service.post(resource + "downloadtemplate", obj, {
            params: { requestID },
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
        });
    }
}