<template>
  <div>
    <v-row>
        <v-col lg="12">
          <carrier></carrier>
        </v-col>
        <!-- <v-col lg="12">
            <material></material>
        </v-col> -->
    </v-row>
  </div>
</template>

<script>
import Material from './Material.vue';
import Carrier from "./Carrier.vue";
export default {
  components: { Carrier, Material },
};
</script>

