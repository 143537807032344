<template scope="props">
  <div>
    <v-container>
      <v-row>
        <v-col>
          <s-toolbar
            remove
            color="primary"
            add
            dark
            @add="saveVehicle()"
            @removed="removeMaterial()"
            label="Asignación Chofer - Vehículo"
          ></s-toolbar>

          <v-card>
            <v-col>
              <v-row>
                <v-col lg="6" class="s-col-form" cols="12">
                  <s-toolbar-person
                    add-driver
                    autofocus
                    label="Chofer"
                    v-model="ReturPerson"
                  ></s-toolbar-person>
                </v-col>
                <v-col lg="6" class="s-col-form" cols="12">
                  <SSelectVehicle v-model="Objvehiculo" add returnObject />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="s-col-form" cols="12">
                  <template>
                    <v-data-table
                      dense
                      v-model="selectedAsignation"
                      :headers="headers"
                      :items="itemsAsignation"
                      item-key="AdvPlaqueVehicle"
                      show-select
                      class="elevation-1"
                    >
                    </v-data-table>
                  </template>
                </v-col>
              </v-row>

              <v-row justify="center">
                <v-container>
                  <v-row justify="center">
                    <v-col lg="6">
                      <v-btn @click="clearOption()" small block
                        ><v-icon x-small left>fas fa-undo-alt</v-icon
                        >Limpiar</v-btn
                      >
                    </v-col>

                    <v-col lg="6">
                      <!-- CUANDO SE PREIONE ESTE BOTON VAS  GUARDAR VERDAD?-->
                      <v-btn small block color="primary " @click="save()">
                        <v-icon small left>fas fa-save</v-icon> Guardar</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </v-row>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
        <v-row>
            <v-col cols="6">
                <s-crud
                    title="Conductor Vehiculo"
                    :filter="filter"
                    height="210"
                    :config="configDriverVehicle"
                    @rowSelected="rowSelected($event)"
                >
                </s-crud>
            </v-col>
        </v-row>
    </v-container>
  </div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import _AsistementDriverVehicle from "@/services/Technicalassistance/AssistementDriverVehicle";
import SToolbarPerson from "@/components/Utils/Person/sToolbarPerson";
import SSelectVehicle from "@/components/Utils/Vehicle/SSelectVehicle";
export default {
  components: { SToolbarPerson, SSelectVehicle },
  data: () => ({
    driverName: null,
    ReturPerson: null,
    vehiculo: null,
    selectedAsignation: [],
    selectChofer: [],
    itemsAsignation: [],
    option: {},
    numberDocument: "",
    headers: [
      { text: "DNI", value: "AdvDriverDNI" },
      { text: "Placa Vehículo", value: "AdvPlaqueVehicle" },
    ],
    Objvehiculo: {
      AsigVehicleObj: null,
    },

    configDriverVehicle: {
        model: {
            AdvID: "ID",
            AdvPlaqueVehicle: "string",
            AdvDriverDNI: "string",
            DriverName: "string"
        },
        service: _AsistementDriverVehicle,
        headers: [
            {text: "ID", value: "AdvID"},
            {text: "Placa", value: "AdvPlaqueVehicle"},
            {text: "DNI", value: "AdvDriverDNI"},
            {text: "Conductor", value: "DriverName"},
            {text: "Plantilla", value: "DownloadPlantilla"},
            {text: "Documento", value: "DownloadPlantilla"}
        ],
    },
  }),
  methods: {
    Initialize() {
      this.filter = {  };
    },
    saveVehicle() {
      // asiganr
      let obj = {
        AdvPlaqueVehicle: this.Objvehiculo.VehPlate,
        AdvDriverDNI: this.ReturPerson,
      };
      this.itemsAsignation.push(obj);
      this.clearOption();
    },
    removeMaterial() {
      this.selectedAsignation.forEach((element) => {
        this.itemsAsignation = this.itemsAsignation.filter(
          (x) => x.Placa != element.Placa
        );
      });
    },
    clearOption() {
      this.Objvehiculo = null;
      this.ReturPerson = null;
    },
    saveDef(item) {
      if (item.DefDescription.length == 0) {
        this.$fun.alert("Registre definición", "warning");
        return;
      }
      item.save();
    },
    //este metodo se ejecutara cuando se presione el boton guardar
    save() {
      //estas son las validaciones
      if (this.itemsAsignation == null) {
        this.$fun.alert("Grilla Vacía", "Warning");
        return;
      }
      for (let i = 0; i < this.itemsAsignation.length; i++) {
        if (this.itemsAsignation[i].AdvDriverDNI == null) {
          this.$fun.alert(
            "Falta Placa del Vehículo," +
              this.selectedAsignation[i].AdvDriverDNI,
            "Warning"
          );
          return;
        }
      }

      //aqui despues de las validaciones deberias llamar al servicio y enviar la lista que quieres guardar
      this.$fun.alert("Seguro de guardar", "question").then((r) => {
        if (r.value) {
          _AsistementDriverVehicle
            .save(this.itemsAsignation, this.$fun.getUserID())
            .then((resp) => {
              this.$fun.alert("Guardado Corectamente", "success");
            });
        }
      });
    },

    rowSelected(item)
    {
      this.driverName = item[0].DriverName;
    }
  },
  created() {
    this.filter = {};
  },
};
</script>
