<template>
    <div>
        <s-crud
            title="Transportista"
            :filter="filter"
            height="450"
            :config="configDriverVehicle"
            :add="this.$fun.getSecurity().IsLevelAdmin"
            :edit="this.$fun.getSecurity().IsLevelAdmin"
            :remove="this.$fun.getSecurity().IsLevelAdmin"
            @rowSelected="rowSelected($event)"
            @save="save($event)"
        >

            <template slot-scope="props" >
                <v-col>
                    <v-row>
                        <v-col lg="6" class="s-col-form" cols="12">
                            <s-toolbar-person
                                v-model="props.item.DocumentNumber"
                                @returnPerson="hadReturnPerson($event, props.item)"
                                addBirthDate
                                addTypeSex
                            >
                            </s-toolbar-person>
                        </v-col>
                        <v-col cols="12" class="s-col-form" lg="6">
                            <s-text disabled label="Transportista" :placeholder="props.item.DriverName" v-model="props.item.NtpFullName" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col lg="6" class="s-col-form" cols="12">
                            <!-- <s-select-article label="Artículo" :text="props.item.ArticleName" return-object v-model="objArticle"></s-select-article> -->
                            <!-- <s-article-search v-else :readonly=editItem  v-model="article" /> -->
                            <s-article-search v-model="objArticle" label="Artículo" :placeholder="props.item.ArticleName"></s-article-search>
                        </v-col>
                        <v-col class="s-col-form" cols="12" lg="6">
                            <s-text v-model="props.item.CcmCount" label="Cantidad" number /> 
                        </v-col>
                    </v-row>
                    <v-row>
                         <v-col class="s-col-form" cols="12" lg="6">
                            <s-select-campaign label="Campaña" v-model="props.item.CcmCampaignNumber"></s-select-campaign>
                        </v-col>
                    </v-row>
                </v-col>
            </template>

            <template v-slot:DownloadTemplate="{ row }">
                <v-btn icon color="primary" v-if="row.DownloadTemplate != null" x-small @click="downloadPlantilla($event)">
                    <v-icon>fas fa-file-download</v-icon>
                </v-btn>
            </template>

            <template v-slot:CcmReturnStatus="{ row }">
                <v-chip style="margin:0px" x-small :color="row.CcmReturnStatus == 1 ? 'success' : 'error'">
                {{ row.CcmReturnStatus == 1 ? "Completo" : "Faltantes" }}
                </v-chip>
            </template>

            <!-- <template v-slot:CcmDocumentName="{ row }">
                <s-load label="Seleccione Archivo" v-if="row.CcmDocumentName == null" v-model="files"></s-load>
            </template> -->

        </s-crud>
    </div>
</template>

<script>
//import { defineComponent } from '@vue/composition-api'
//import _AsistementDriverVehicle from "@/services/Technicalassistance/AssistementDriverVehicle";
import _CommitmentCarrierMaterial from "@/services/Technicalassistance/CommitmentCarrierMaterial";
import SToolbarPerson from "@/components/Utils/Person/sToolbarPerson";
import SSelectArticle from '@/components/TecnicalAssistence/CommitmentCarrierMaterial/sSelectArticle.vue';
import SSelectCampaign from '../../../components/Utils/SSelectCampaign.vue';
import sArticleSearch from "@/components/Utils/Logistics/SArticleSearch";

export default {
  components: {SToolbarPerson, SSelectArticle, SSelectCampaign, sArticleSearch },
  props: {

  },
  data: () => ({
      ReturPerson: null,
      files: [],
      itemSelected: {},
    objArticle:{
        
    },
    configDriverVehicle: {
        model: {
            CcmID: "ID",
            DriverName: "string",
            DocumentNumber: "string",
            ArticleName: "string",
            CcmCount: "int",
            DownloadTemplate: "int",
            CcmDocumentName: "string",
            campaign: "string",
            CcmReturnStatus: "int",
            CcmDebit: "int",
        },
        service: _CommitmentCarrierMaterial,
        headers: [
            {text: "ID", value: "CcmID"},
            {text: "Código", value: "CcmCode"},
            {text: "Transportista", value: "DriverName"},
            {text: "DNI", value: "DocumentNumber"},
            {text: "Material", value: "ArticleName"},
            {text: "Cantidad Total", value: "CcmCount"},
            {text: "Debe", value: "CcmDebit"},
            {text: "Estado", value: "CcmReturnStatus"},
            {text: "N° Campaña", value: "campaign"},
            {text: "Plantilla", value: "DownloadTemplate"},
            /* {text: "Documento", value: "CcmDocumentName"}, */
            
        ],
    },
  }),
  methods: {
    hadReturnPerson(value, item){
        item.NtpFullName = value.NtpFullName;
        item.DrvID = value.PrsID;
    },
    Initialize() {
      this.filter = {  };
    },
    clearOption() {
      this.Objvehiculo = null;
      this.ReturPerson = null;
    },
    rowSelected(rows)
    {
        if (rows.length > 0) {
            this.itemSelected = rows;
        }
    },

    save(item){
        item.ArtID = this.objArticle.ArtCode;
        item.SecStatus = 1;
        if(item.DocumentNumber.length == 0){
            this.$fun.alert("Ingrese Transportista", "warning")
            return;
        }
        if(!item.ArtID){
            this.$fun.alert("Ingrese Artículo", "warning")
            return;
        }
        if(item.CcmCount.length == 0){
            this.$fun.alert("Ingrese Cantidad", "warning")
            return;
        }
        item.save().then((r)=>{
                   this.Objvehiculo = {}
        this.objArticle = {}
        });
     
    },

    downloadPlantilla()
    {
        let obj = this.itemSelected[0];
        this.$fun.alert("¿Seguro de descargar?", "question").then((r) => {
        _CommitmentCarrierMaterial.downloadtemplate(obj, this.$fun.getUserID())
            .then((r) => {
                this.$fun.downloadFile(
                    r.data,
                    this.$const.TypeFile.PDF,
                    "Compromiso"
                );
            });
        });
    },
    
  },
  created() {
    this.filter = {};
  },
};
</script>
